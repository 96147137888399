var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-5 col-md-8 mb-3"
  }, [_c('FormItem', {
    staticClass: "col-md-6",
    attrs: {
      "id": "graph-date-range",
      "type": "dateRangePicker"
    },
    on: {
      "input": _vm.onDateRangeUpdate
    },
    model: {
      value: _vm.options.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "dateRange", $$v);
      },
      expression: "options.dateRange"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_vm._m(0), _c('div', {
    staticClass: "block-content"
  }, [_vm.loading.getActiveBilling ? _c('Spinner') : _c('div', [_c('div', {
    staticClass: "font-w600 font-size-h3 mb-3"
  }, [_vm._v(_vm._s(_vm.activeBilling.companyName))]), _c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("Accounts (Captur)")]), _c('td', [_vm._v(_vm._s(_vm.activeBilling.capturAccounts) + " / " + _vm._s(_vm.activeBilling.baseAccounts))])]), _c('tr', [_c('th', [_vm._v("Assets (Captur)")]), _c('td', [_vm._v(_vm._s(_vm.activeBilling.capturAssets) + " / " + _vm._s(_vm.activeBilling.baseAssets))])])])]), _c('div', {
    staticClass: "font-w600 font-size-h4 mb-3"
  }, [_vm._v("Captur Uploads")]), _c('table', {
    staticClass: "table"
  }, [_c('tbody', [_vm._l(_vm.activeBilling.uploadUsers, function (count, user) {
    return _c('tr', {
      key: user
    }, [_c('th', [_vm._v(_vm._s(user))]), _c('td', [_vm._v(_vm._s(count))])]);
  }), _c('tr', [_c('th', {
    staticClass: "text-danger"
  }, [_vm._v("Errored")]), _c('td', [_vm._v(_vm._s(_vm.activeBilling.erroredCount))])])], 2)]), _c('div', {
    staticClass: "font-w600 font-size-h4 mb-3"
  }, [_vm._v("Invoice Confirmations")]), _c('table', {
    staticClass: "table"
  }, [_c('tbody', _vm._l(_vm.activeBilling.confirmedUsers, function (count, user) {
    return _c('tr', {
      key: user
    }, [_c('th', [_vm._v(_vm._s(user))]), _c('td', [_vm._v(_vm._s(count))])]);
  }), 0)])])], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-header block-header-default"
  }, [_c('h3', {
    staticClass: "block-title"
  }, [_vm._v("Active Billing")])]);
}]

export { render, staticRenderFns }