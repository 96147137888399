<template>
  <div>
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-8 mb-3">
        <FormItem id="graph-date-range" v-model="options.dateRange" class="col-md-6" type="dateRangePicker" @input="onDateRangeUpdate" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="block block-rounded">
          <div class="block-header block-header-default">
            <h3 class="block-title">Active Billing</h3>
          </div>
          <div class="block-content">
            <Spinner v-if="loading.getActiveBilling" />
            <div v-else>
              <div class="font-w600 font-size-h3 mb-3">{{ activeBilling.companyName }}</div>
              <table class="table">
                <tbody>
                  <tr>
                    <th>Accounts (Captur)</th>
                    <td>{{ activeBilling.capturAccounts }} / {{ activeBilling.baseAccounts }}</td>
                  </tr>
                  <tr>
                    <th>Assets (Captur)</th>
                    <td>{{ activeBilling.capturAssets }} / {{ activeBilling.baseAssets }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="font-w600 font-size-h4 mb-3">Captur Uploads</div>
              <table class="table">
                <tbody>
                  <tr v-for="(count, user) in activeBilling.uploadUsers" :key="user">
                    <th>{{ user }}</th>
                    <td>{{ count }}</td>
                  </tr>
                  <tr>
                    <th class="text-danger">Errored</th>
                    <td>{{ activeBilling.erroredCount }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="font-w600 font-size-h4 mb-3">Invoice Confirmations</div>
              <table class="table">
                <tbody>
                  <tr v-for="(count, user) in activeBilling.confirmedUsers" :key="user">
                    <th>{{ user }}</th>
                    <td>{{ count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import FormItem from '@/components/FormItem';
import Spinner from '@/components/Spinner';

export default {
  name: 'AdminDashboard',
  components: {
    FormItem,
    Spinner
  },
  data() {
    return {
      options: {
        startDate: moment().startOf('month'),
        endDate: moment().startOf('month').add(1, 'month'),
        dateRange: [moment().startOf('month'), moment().startOf('month').add(1, 'month')],
        predefinedRange: moment().startOf('month')
      }
    };
  },
  computed: {
    ...mapGetters({
      activeBilling: 'admin/activeBilling',
      loading: 'admin/loading'
    })
  },
  mounted() {
    this.getActiveBilling({
      startDate: moment().subtract(1, 'month').startOf('month').toDate(),
      endDate: moment().startOf('month').toDate(),
      companyId: this.$auth.companyId
    });
  },
  methods: {
    ...mapActions({
      getActiveBilling: 'admin/getActiveBilling'
    }),
    onDateRangeUpdate() {
      this.options.startDate = this.options.dateRange[0];
      this.options.endDate = this.options.dateRange[1];

      this.getActiveBilling({
        startDate: this.options.startDate,
        endDate: this.options.endDate,
        companyId: this.$auth.companyId
      });
    }
  }
};
</script>
